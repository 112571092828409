import React, { Component } from 'react'
import Academy from '../components/Academy'
import Footer from '../components/Common/Footer'
import Header from '../components/Common/Header'
import SEO from '../components/Common/SEO'
import Layout from '../layouts/Layout'

export default class academy extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
        isLoaded: false
      }
    }

    componentDidMount = () => {
        this.setState({
            isLoaded: true
        })
    }

    render() {
        return (
            <div id="scrollable">
                <Header isTransparent={false} isLoaded={this.state.isLoaded}/>
                <SEO
                    title='Verse Estate - Academy'
                />
                <div className='snap'>
                    <Academy/>
                </div>
                <div>
                    <Footer />
                </div>
            </div>
        )
    }
}
