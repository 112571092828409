import { Link } from 'gatsby'
import React, { Component } from 'react'
import '../styles/scss/academy.scss'

export default class Academy extends Component {
    constructor(props) {
        super(props)

        this.state = {
            timer: 22,
            date: 'The 26th of March',
            socials : [
                {
                    icon:'https://cdn-icons-png.flaticon.com/512/733/733635.png',
                    url:'https://x.com/VerseWorld'
                },
                {
                    icon:'https://cdn-icons-png.flaticon.com/512/739/739193.png',
                    url:'https://www.instagram.com/verseworld'
                },
                {
                    icon:'https://cdn-icons-png.flaticon.com/512/3536/3536569.png',
                    url:'https://ae.linkedin.com/company/verse-estate'
                }
            ]
        }
    }

    componentDidMount = () => {
        this.startTimer()
    }

    startTimer = () => {
        this.timer = setInterval(() => {
            if (this.state.timer <= 0) {
                return this.stopTimer()
            }

            this.setState({
                isDisplayed: false
            }, () => {
                this.setState({
                    timer: this.state.timer - 1,
                    isDisplayed: true
                })
            })
        }, 1000);
    }

    stopTimer = () => {
        clearInterval(this.timer);

    }
    renderSocials = () => {
        return(
            <div className='socials-container'>
                {
                    this.state.socials.map(social => {
                        return(
                            <a href={social?.url} target='_blank'>
                                <div className='social-icon' style={{backgroundImage: `url(${social?.icon})`}}/>
                            </a>
                        )
                    })
                }
            </div>
        )
    }

    renderTime = () => {
        return (
            <h4>11 days : 16 hrs : <span>{this.state.timer}</span> mins</h4>
        )
    }

    render() {
        return (
            <>
                <section className='academy' id='academy'>
                    <div className='titles'>
                        <h4>{this.state.date}</h4>
                        <h3>All Good Things Come to Those who Wait...</h3>
                    </div>

                    <div className='image-container'>
                        <div className='image'>
                            <div className='image-svg' />
                        </div>
                    </div>
                    {this.renderSocials()}
                </section>
            </>
        )
    }
}
